.services-container{
  background: url("/assets/img/web-bg-gimmick.png") no-repeat top right;
  background-size: 250px;
}

.section-header{
  margin-bottom: 25px;
  .title {
    font-size: 18px;
    color: #0fa400;
  }
  .subtitle{
    font-size: 60px;
    color: #0f7e00;
    span.highlight{
      padding: 0 15px 0;
      background-color: #11f3002F;
    }
  }
  @media (max-width: 640px) {
    .title{
      font-size: 14px;
    }
    .subtitle{
      font-size: 22px;
    }
  }
}
