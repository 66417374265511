.page-container{
  padding: 10px 20px 10px;
  .page-input>label,.page-per-page>label{
    font-size: 11px;
  }
  .page-input{
    text-align: right;
    max-width: 70px;
  }
  .page-item{
    .page-link{
      color: #0fa400;
    }
    &.active .page-link{
      color: white;
      background-color: #0fa400;
      border-color: #0fa400;
    }
  }
}
