@font-face {
  font-family: "Bubblegum Sans";
  src: local('Bubblegum Sans'), url("/assets/fonts/BubblegumSans-Regular.ttf") format('truetype');
}

body{
  font-family: "Bubblegum Sans";
  background-color: #f3f3f3;
}

.content {
  padding: 0;
  background-color: white;
}

@for $i from 0 to 10 {
  .p#{$i}{
    padding: 5px * $i;
  }
  .ph#{$i}{
    padding-left: 5px * $i;
    padding-right: 5px * $i;
  }
  .pv#{$i}{
    padding-top: 5px * $i;
    padding-bottom: 5px * $i;
  }
  .pt#{$i}{
    padding-top: 5px * $i;
  }
  .pb#{$i}{
    padding-bottom: 5px * $i;
  }
  .pl#{$i}{
    padding-left: 5px * $i;
  }
  .pr#{$i}{
    padding-right: 5px * $i;
  }

  .m#{$i}{
    margin: 5px * $i;
  }
  .mh#{$i}{
    margin-left: 5px * $i;
    margin-right: 5px * $i;
  }
  .mv#{$i}{
    margin-top: 5px * $i;
    margin-bottom: 5px * $i;
  }
  .mt#{$i}{
    margin-top: 5px * $i;
  }
  .mb#{$i}{
    margin-bottom: 5px * $i;
  }
  .ml#{$i}{
    margin-left: 5px * $i;
  }
  .mr#{$i}{
    margin-right: 5px * $i;
  }
}

.section{
  &-container{
    background: url("/assets/img/web-bg-gimmick.png") no-repeat top right;
    background-size: 250px;
  }

  &-header{
    margin-bottom: 25px;
    .title {
      font-size: 18px;
      color: #0fa400;
    }
    .subtitle{
      font-size: 60px;
      color: #0f7e00;
      span.highlight{
        padding: 0 15px 0;
        background-color: #11f3002F;
      }
    }
    @media (max-width: 640px) {
      .title{
        font-size: 14px;
      }
      .subtitle{
        font-size: 22px;
      }
    }
  }

  &-content{
    padding: 30px 50px 30px;
    margin-bottom: 30px;
    background-color: rgba(200,200,200,0.15);
    border-radius: 8px;

    @media (max-width: 640px) {
      padding: 10px 30px 10px;
    }
  }

}
