.site-header{
  position: relative;
  background-color: white;
}

li.nav-item > a,a:active,a:focus {
  color: #43bc87;

  &:hover{
    color: #0f7e00;
  }
}

li.nav-item > a.active {
  color: #0f7e00;
}


.collapse.navbar-collapse > .navbar-nav.contact{
  @media (max-width: 640px){
    display: none !important;
  }
}
