.linker{
  &-header-logo{
    background: url("/assets/img/logo.png") no-repeat center left;
    background-size: 100px;
    height: 120px;
  }

  &-header{
    font-size: 18px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  &-list{
    padding-inline-start: 0!important;
    list-style-type: none;
    li {
      font-size: 14px;
      font-weight: lighter;
      color: #cfcfcf;
      margin: 5px 0 5px;
      a{
        text-decoration: none;
        &:link, &:active, &:visited{
          color: #cfcfcf;
        }
        &:hover{
          color: white;
        }
      }
    }
  }
}
